<script>
import { OptionsComponent } from '@drapejs/core';
import { setDefaultMeta } from "@/composables/useMeta";

export default {
  extends: OptionsComponent,
  computed: {
    blocks() {
      return this.$page.blocks;
    },
  },
  mounted() {
    setDefaultMeta();
  },
};
</script>

<template>
  <div class="not-found">
    <blocks-repeater v-if="blocks" :blocks="blocks.Main" />
  </div>
</template>

<style>

</style>
